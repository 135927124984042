<template>
    <div class="about">
        <div class="content">
            <div class="bookmark_row">
                <div class="bookmark">
                     <img class="bookmark_icon" src="@/img/icon/about-2.png">
                    关于我们
                </div>
                <div class="line"></div>
                <div class="bookmark disable" @click="changePage(1)">
                    <img class="bookmark_icon" src="@/img/icon/disclaimer-1.png">
                    免责声明
                </div>
                <div class="line"></div>
                <div class="bookmark disable" @click="changePage(2)">
                    <img class="bookmark_icon" src="@/img/icon/rules-1.png">
                    玩法规则
                </div>
            </div>
            <div class="text_box">
                <p>
                    彩云资讯是一家在互联网构建中国和世界各重要彩票资讯平台的高科技公司，是以彩票资讯业务为核心，兼顾软件研发与网站建设等互联网业务的高效、多元的综合性互联网公司。
                </p>
                <br>
                <br>
                <p>
                    公司经营网站为”彩云资讯”，网址： www.lottos.cloud.
                </p>
                <br>
                <br>
                <p>
                    公司由数十位彩票界与IT行业资深人士构成，具备一流的网站设计与研发能力，立足于中国千亿元的彩票市场，与彩票界内数百位彩票分析师合作，共建彩票行业一流的垂直门户。
                </p>
                <br>
                <br>
                <p>
                    企业使命是：客户享受优越的关怀和服务，员工拥有美好的生活和前景，股东得到稳定的资产增长和回报。
                </p>
                <br>
                <br>
                <p>
                    公司倡导创新敢为的精神，形成了”诚信、创新、团结、进取”的价值观。公司贯彻绩效奖励淘汰机制，不拘一格选择提拔优秀人才。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: 'About',
        components: {
        },
        data() {
            return {
                pageUrl: [
                    "about", "disclaimer", "gamerule"
                ],
            }
        },
        computed: {
        },
        methods: {
            changePage (i) {
                this.$router.push(this.pageUrl[i]);
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    .about{
        .content {
            width: 1440px;
            height: auto;
            margin: auto;
            margin-top: 0.5rem;
            .bookmark_row{
                display: flex;
                justify-content: left;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                margin-bottom: 40px;
                .bookmark{
                    z-index: 10;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: bold;
           
                    text-align: center;
                    color: #ff6819;
                    cursor: pointer;
                    .bookmark_icon{
                      width: 22px;
                      vertical-align: text-bottom;
                    }
                }
                .disable{
                    color: #0f2957;
                }
                .line{
                    height: 14px;
                    width: 1px;
                    background-color: #d0d1d3;
                    margin: 0 16px;
                }
            }
            .text_box{
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                margin: auto;
                padding: 0.8rem;
                box-sizing: border-box;
                margin-bottom: 0.8rem;
                border-radius: 20px;
                box-shadow: 0 3px 8px 0 rgba(2, 18, 52, 0.2);
                border: solid 3px #e0d6a7; 
                background: #fff;
                width: 100%;
                p{
                    font-size: 17px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 30px;
                    text-align: left;
                    color: #646363; 
                }
            }
        }
    }    
</style>